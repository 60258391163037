import { Icon } from '@repo/ui/components/Icon.js'
import { cn } from '@repo/ui/lib/utils'
import type { ComponentProps } from 'react'

export const IconStamp = ({
  className,
  size,
  ...props
}: ComponentProps<typeof Icon>) => {
  return (
    <div className="rounded-sm bg-gradient-to-br from-purple-400 to-pink-300 p-1 shadow-sm">
      <Icon className={cn('text-white/80', className)} {...props} />
    </div>
  )
}
